import React, { useEffect, useMemo } from "react";
import ThreadHelper from "../../../../entities/thread/thread-helper";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import Events from "../../../../const/events";
import { Tooltip } from "react-tooltip";

const ChatMessage = ({ message, selectedThread, direction, previousMessage }) => {
  const [shouldGroupMessage, setShouldGroupMessage] = React.useState(false);
  const _showQRPopup = () => {
    EventEmitter.dispatch(Events.SHOW_QR_CODE_POPUP, {
      id: message?._by?._id,
      title: message?._by?.name,
    });
  };

  const _getOtherParticipantName = (conatctObj, message) => {
    // How to determine the other participant name
    // First get the first name of both participants
    // Then check if it's within the message
    // If yes then the other participant is the one who's name is not in the message
    // Also if both are in the message then the other participant is the one who's name has lower index
    const participants = selectedThread?._contacts;
    const participantNames = participants.map((participant) => participant.name);
    let otherParticipantName = participantNames.filter(
      (name) => !message.includes(name)
    );
    if (otherParticipantName.length === 0) {
      otherParticipantName = participantNames.sort(
        (a, b) => message.indexOf(a) - message.indexOf(b)
      )[0];
    }
    return otherParticipantName;
  }

  const leftChatColorClass = useMemo(() => {
    let colorClass = "yellow";
    // Check if thread type is room
    if (selectedThread?.type === "room" && selectedThread?._contacts) {
      // Now check if message is sent by selectedThread._contacts[0]._id
      if (message?._by?._id === selectedThread?._contacts[0]?._id) {
        colorClass = "orange";
      } else {
        colorClass = "yellow";
      }
    }
    return colorClass;
  }, []);

  const botMessageType = useMemo(() => {
    // if message has "joined the room" as a substring then it's success
    // if message has "left the room" as a substring then it's danger
    let messageType = "";
    if (message.message.includes("joined the room")) {
      messageType = "success";
    } else if (message.message.includes("left the room")) {
      messageType = "danger";
    }
    return messageType;
  }, [])

  useEffect(() => {
    if (previousMessage?.msgType === 'messages' && message?.msgType === 'messages' && previousMessage?._by?._id && message?._by?._id && (previousMessage?._by?._id === message?._by?._id)) {
      setShouldGroupMessage(true);
    }
  }, [previousMessage])


  if (message.msgType !== "messages") {
    // System message
    if (selectedThread?.type === "room") {

      return (
        <div className="middleNotification">
          {/* <span >
                    <b>Wojciech Woźnicki</b> left the room
                    <span className="timestamp">4:30 pm</span>
                  </span> */}
          <span className={botMessageType}>
            Sent "<b>{message.message}</b>" to <b>
              {
                _getOtherParticipantName(message?._by, message.message)
              }
            </b>

            <span className="timestamp">
              {ThreadHelper.getFormattedDateTime(message.createdAt, true)}
            </span>

          </span>
        </div>
      )
    } else {
      return (
        // <div className="media sending-loader">
        <div className="media">
          {/* <div className="media-img">
            <img
              src="assets/images/ai.jpg"
              alt="user"
              className="rounded-circle thumb-sm"
            />
          </div> */}
          <div className="media-body reverse">
            <div className="chat-msg">
              <p>
                {message.message}
                <span className="sender">System</span>
                <span className="timestamp">
                  {ThreadHelper.getFormattedDateTime(message.createdAt, true)}

                  <span className="status">
                    {message.status == "read" ? (
                      <span className="read" title="Read at 12:00 pm">
                        <i className="fa fa-check-double"></i>
                      </span>
                    ) : (
                      <span className="notRead" title="Delivered">
                        <i className="fa fa-check"></i>
                      </span>
                    )}
                  </span>
                </span>
              </p>
            </div>
            {/* <div className="chat-msg">
          <p>
            There are many variations of passages of Lorem Ipsum available.
            <span className="timestamp">2:30pm</span>
            
          </p>
        </div> */}
          </div>
        </div>
      );
    }
  }
  return (
    <>

      {direction === "outgoing" ? (
        // <div className="media sending-loader">
        <div className="media ">
          {/* <div className="media-img">
            <img
              src="assets/images/profile-avatar.png"
              alt="user"
              className="rounded-circle thumb-sm"
            />
          </div> */}
          <div className="media-img reverse">
            <span className="fullName">{message._by.email}</span>
            <div className="avatar-box thumb-sm align-self-center">
              <span
                className="thumb-sm justify-content-center d-flex align-items-center bg-soft-primary rounded-circle"
                onClick={_showQRPopup}
              >
                d
              </span>
            </div>
          </div>
          <div className="media-body reverse">
            <div className="chat-msg">
              <p>
                {message.message}
                {/* <span className="sender">{message._by.email}</span> */}
                <span className="timestamp">
                  {ThreadHelper.getFormattedDateTime(message.createdAt, true)}

                  <span className="status">
                    {message.status == "read" ? (
                      <span className="read" title="Read at 12:00 pm">
                        <i className="fa fa-check-double"></i>
                      </span>
                    ) : (
                      <span className="notRead" title="Delivered">
                        <i className="fa fa-check"></i>
                      </span>
                    )}
                  </span>
                </span>
              </p>
            </div>
            {/* <div className="chat-msg">
              <p>
                There are many variations of passages of Lorem Ipsum available.
                <span className="timestamp">2:30pm</span>
                
              </p>
            </div> */}
          </div>
        </div>
      ) : (
        <div className={`media ${leftChatColorClass}`}>  {/* orange for who starts the convo & yellow for the other person */}
          {
            shouldGroupMessage ? <></> : (
              <div className="media-img">
                <div className="avatar-box thumb-sm align-self-center">
                  <span
                    className="thumb-sm justify-content-center d-flex align-items-center bg-soft-primary rounded-circle"
                    onClick={_showQRPopup}
                  >
                    {message?._by?.name ? message?._by?.name[0] : "U"}
                  </span>
                </div>
                <span className="fullName">{message?._by?.name}</span>
              </div>
            )
          }

          <div className="media-body">
            <div className="chat-msg">
              <p>
                {message.message}{" "}
                <span className="timestamp">
                  {ThreadHelper.getFormattedDateTime(message.createdAt, true)}
                </span>
              </p>
            </div>
            {/* <div className="chat-msg">
              <p>
                There are many variations of passages of Lorem Ipsum available.
                <span className="timestamp">2:30pm</span>
              </p>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ChatMessage;
