import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import Events from "../../../../const/events";
import ContactHelper from "../../../../entities/contact/contact-helper";
import ThreadHelper from "../../../../entities/thread/thread-helper";
import PhoneInputWithCountrySelect from "react-phone-number-input";

const NewThreadPopup = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  useEffect(() => {
    const _registerEvents = () => {
      EventEmitter.subscribe(Events.SHOW_CREATE_THREAD_POPUP, () => {
        setPopupVisible(true);
      });
      EventEmitter.subscribe(Events.HIDE_CREATE_THREAD_POPUP, () => {
        setPopupVisible(false);
      });
    };

    const _unregisterEvents = () => {
      EventEmitter.cancelAll(Events.SHOW_CREATE_THREAD_POPUP);
      EventEmitter.cancelAll(Events.HIDE_CREATE_THREAD_POPUP);
    };

    _registerEvents();
    return () => {
      _unregisterEvents();
    };
  }, []);

  const handleMobileNumberChange = (value) => {
    // Remove all special characters except "+"
    value = value.replace(/[^+\d]/g, "");

    // Ensure it starts with "+" for country code
    if (!value.startsWith("+")) {
      setError("Phone number must include a country code.");
    } else {
      setError(null);
    }

    setMobileNumber(value);
  };

  const handleCreateThread = async () => {
    // Ensure number starts with a country code and is at least 10 digits long
    if (!mobileNumber.startsWith("+")) {
      setError("Phone number must include a country code.");
      return;
    }

    // Remove "+" and check length
    const numericPart = mobileNumber.replace("+", "");
    if (numericPart.length < 10) {
      setError("Please provide a valid number with country code.");
      return;
    }

    setIsLoaderActive(true);
    setError(null);

    try {
      // Create a new contact
      const { contactId } = await ContactHelper.createNewContact({
        name: name?.trim().length ? name : "unknown contact",
        phone: mobileNumber,
      });

      // Create a new thread
      await ThreadHelper.createNewThread({
        contactId,
        title: name?.trim().length ? name : "unknown contact",
        phone: mobileNumber,
      });

      setPopupVisible(false);
    } catch (err) {
      console.error("API Error:", err);

      // Show API error message if available
      if (err.response?.data?.message) {
        setError(err.response.data.message);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsLoaderActive(false);
    }
  };

  useEffect(() => {
    if (isPopupVisible) {
      setMobileNumber("");
      setName("");
      setError(null);
    }
  }, [isPopupVisible]);

  if (!isPopupVisible) {
    return null;
  }

  return (
    <div className="modal-wrapper">
      <div
        className="modal fade show"
        id="newMessageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="newMessageModalTitle"
        aria-hidden="false"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <OutsideClickHandler
            onOutsideClick={() => {
              setPopupVisible(false);
            }}
          >
            <div className="modal-content">
              <div className="modal-header py-3">
                <h6 className="modal-title m-0" id="newMessageModalTitle">
                  Create a new message
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setPopupVisible(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-lg-5 text-center align-self-center">
                    <img
                      src="assets/images/small/new-chat.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-12">
                        <h4 className="text-dark text-center mb-4">
                          Start new conversation!
                        </h4>
                      </div>
                      <div className="col-12 mb-3">
                        <label className="form-label mb-0">Mobile number</label>
                        <div className="input-group">
                          <PhoneInputWithCountrySelect
                            placeholder="Enter phone number"
                            defaultCountry="GB"
                            value={mobileNumber}
                            onChange={handleMobileNumberChange}
                          />
                        </div>
                        {error && (
                          <div className="text-danger mt-2">{error}</div>
                        )}
                      </div>
                      <div className="col-12">
                        <label className="form-label mb-0">Full name</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter full name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex align-items-center justify-content-between">
                <button
                  type="button"
                  className="btn btn-soft-danger btn-sm"
                  data-bs-dismiss="modal"
                  onClick={() => setPopupVisible(false)}
                >
                  Cancel
                </button>
                {isLoaderActive ? (
                  <div className="thread-popup-loader-wrapper">
                    <img
                      src="assets/images/ring-loader.svg"
                      alt="Loading..."
                      className="loader"
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleCreateThread}
                  >
                    Continue
                  </button>
                )}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </div>
  );
};

export default NewThreadPopup;
