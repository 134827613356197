// ResetPasswordPage.js
import React, { useState } from "react";
import AuthHelper from "../../helpers/auth-helper/auth-helper";
import router from "../../../../shared/top-level-declarations/history";
import "./reset-password-page.scss";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleResetPassword = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      await AuthHelper.resetPassword(email);
      setSuccess(
        "If an account exists with this email, you will receive a password reset link shortly."
      );
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid login-bg">
      <div className="row vh-100 d-flex justify-content-center">
        <div className="col-12 align-self-center">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4 mx-auto">
                <div className="card">
                  <div className="card-body p-0 auth-header-box">
                    <div className="text-center p-3">
                      <h4 className="mt-3 mb-1 fw-semibold font-18">
                        Reset Your Password
                      </h4>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <form className="my-4" onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group mb-2">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12">
                          <div className="d-grid mt-3">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={handleResetPassword}
                              disabled={loading}
                            >
                              {loading ? "Sending..." : "Send Reset Link"}
                            </button>
                          </div>
                        </div>
                      </div>
                      {error && <p className="text-danger mt-2">{error}</p>}
                      {success && (
                        <p className="text-success mt-2">{success}</p>
                      )}

                      <div className="d-grid mt-3">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => router.navigate("/login")}
                        >
                          Back to Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
