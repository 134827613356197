// firebase-auth-helper.js
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";

export default class FirebaseAuthHelper {
  static firebaseInitialized = false;
  static firebaseAuth = null;

  // Initialize Firebase only once
  static initFirebase() {
    if (!FirebaseAuthHelper.firebaseInitialized) {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      };

      const app = initializeApp(firebaseConfig);
      FirebaseAuthHelper.firebaseAuth = getAuth(app);
      FirebaseAuthHelper.firebaseInitialized = true;
      console.log("Firebase initialized");
    }
  }

  // Login function
  static async login({ email, password }) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        FirebaseAuthHelper.firebaseAuth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // Logout function
  static async logout() {
    try {
      await signOut(FirebaseAuthHelper.firebaseAuth);
      return true;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // Get Firebase Auth Token
  static async getFirebaseAuthToken() {
    const user = FirebaseAuthHelper.firebaseAuth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      return token;
    } else {
      throw new Error("No user is signed in");
    }
  }

  // Check if a user is signed in
  static getCurrentUser() {
    try {
      return FirebaseAuthHelper.firebaseAuth?.currentUser;
    } catch (error) {
      console.log('error :>> ', error);
    }
  }

  // Reset Password Function
  static async resetPassword(email) {
    try {
      await sendPasswordResetEmail(FirebaseAuthHelper.firebaseAuth, email);
    } catch (error) {
      throw new Error("Failed to send reset email. " + error.message);
    }
  }
}
